//
//
// Stylesheets are included in a logical order and thus a logical cascade
// in order to easily override styles.
//
//

//
// 1. Vendor components.
//

// bootstrap
@import '../../node_modules/bootstrap/less/variables.less';
@import '../../node_modules/bootstrap/less/mixins.less';
@import '../../node_modules/bootstrap/less/normalize.less';
@import '../../node_modules/bootstrap/less/utilities.less';
@import '../../node_modules/bootstrap/less/scaffolding.less';
@import '../../node_modules/bootstrap/less/component-animations.less';
//@import '../../node_modules/bootstrap/less/tables.less';
@import '../../node_modules/bootstrap/less/type.less';
@import '../../node_modules/bootstrap/less/grid.less';
//@import '../../node_modules/bootstrap/less/navs.less';
//@import '../../node_modules/bootstrap/less/navbar.less';
@import '../../node_modules/bootstrap/less/panels.less';
@import '../../node_modules/bootstrap/less/alerts.less';
@import '../../node_modules/bootstrap/less/buttons.less';
@import '../../node_modules/bootstrap/less/button-groups.less';
@import '../../node_modules/bootstrap/less/dropdowns.less';
//@import '../../node_modules/bootstrap/less/popovers.less';
//@import '../../node_modules/bootstrap/less/progress-bars.less';
@import '../../node_modules/bootstrap/less/forms.less';
//@import '../../node_modules/bootstrap/less/glyphicons.less';
@import '../../node_modules/bootstrap/less/modals.less';
@import '../../node_modules/bootstrap/less/close.less';
@import '../../node_modules/bootstrap/less/input-groups.less';
@import '../../node_modules/bootstrap/less/responsive-utilities.less';
//@import '../../node_modules/bootstrap/less/carousel.less';
@import '../../node_modules/bootstrap/less/badges.less';
@import '../../node_modules/bootstrap/less/tooltip.less';
//@import '../../node_modules/bootstrap/less/wells.less';
@icon-font-path: 'fonts/'; // relative to our built css file

// ui-select
@import (less) '../../node_modules/ui-select/dist/select.css';
// .ui-select-choices{
//     &:extend(.ui-select-choices);
//     border:1px solid #ddd;
//     margin-bottom:0;
// }


// font-awesome
@import '../../node_modules/font-awesome/less/font-awesome.less';
@fa-font-path: "../assets/node_modules/font-awesome/fonts";

// roboto
@import '../../node_modules/roboto-fontface/css/roboto/less/roboto-fontface.less';
@roboto-font-path: "../assets/node_modules/roboto-fontface/fonts";

//
// 2. Global app styles.
//
@import 'select.less';

//
// 3. Common component styles
//
@import '../common/alert/alert.less';
@import '../common/fields/reference/reference.less';
@import '../common/generic/help-overlay.less';
@import '../common/logo/logo.less';
@import '../common/multi-step-form/multi-step-form.less';
@import '../common/shared/fields/address/address-field.less';
@import '../common/validation/directives.less';

//
// 4. View specific styles.
//
@import '../app/registration/registration.less';

//
// 5. Variables.
//

// TODO: stuff that should not be in here ... 
.control-label .optional {
    font-weight: normal;
    color: #999;
}

fieldset[disabled] .ui-select-toggle:hover {
    background-color: #eeeeee !important;
}

.row > .form-group > .validation-tip {
    margin-left: -15px;
}

.ui-select-search {
  width: 100%;
}

.text-danger-light {
    color: darken(#CF1028, 3%);
}

.text-warning-light {
    color: darken(#FDB45C, 6%);
}

.text-success-light {
    color: #0B821F;
}

.fade-animation.ng-enter,
.fade-animation.ng-leave,
.fade-animation.ng-hide-add,
.fade-animation.ng-hide-remove {
    transition: 0.25s cubic-bezier(0,.6,.4,1) opacity;
    display: block !important;
}

.fade-animation.ng-enter,
.fade-animation.ng-leave.ng-leave-active,
.fade-animation.ng-hide-remove,
.fade-animation.ng-hide-add.ng-hide-add-active {
    opacity: 0;
}

.fade-animation.ng-enter.ng-enter-active,
.fade-animation.ng-leave,
.fade-animation.ng-hide-add,
.fade-animation.ng-hide-remove.ng-hide-remove-active {
    opacity: 1;
}

body{
    font-family: 'Roboto', Arial, sans-serif;
}
