@steel: #06303D;
@red: #CF1028;
@disabled: #AAAAAA;
@grey: #777777;
@blue: #00529e;

.multi-step-form-step.ng-enter,
.multi-step-form-step.ng-hide-remove {
    transition-delay: 0.25s !important;
}

// // breadcrumbs

.multi-step-form{
    &-breadcrumbs{
        list-style: none;
        @media only screen and (max-width: 750px){
            margin-bottom:25px;
        }
        @media only screen and (min-width: 750px){
            position:relative;
            &:before{
                content:'';
                width:80%;
                max-width:690px;
                height:1px;
                background:#e5e5e5;
                top:15px;
                left:0;
                display: block;
                position: absolute;
                z-index: -1;
            }
        }
        @media only screen and (min-width: 992px){
            position:relative;
            &:before{
                content:'';
                max-width:850px;
                top:18px;
            }
        }
    };

    &-breadcrumb{
        font-size:14px;
        line-height:1.4286em;
        height:1.4286em;
        margin-bottom:10px;

        &-icon{
            border-radius: 100px;
            width:1.4286em;;
            color: white;
            text-align: center;
            display: inline-block;
            .fa{
                font-size: 0.8em;
            }
        }

        .multi-step-form-breadcrumb-icon{background: @disabled;}
        .multi-step-form-breadcrumb-title{color: @disabled;}

        &.active{
            .multi-step-form-breadcrumb-icon{background: @red;}
            .multi-step-form-breadcrumb-title{color: @red;}
        }
        &.visited{
            .multi-step-form-breadcrumb-icon{background: @steel;}
            .multi-step-form-breadcrumb-title{color: @steel;}
        }

        
        @media only screen and (min-width: 750px){
            background:white;
            display:inline-block;
            margin-right:3.5%;
            padding:7px;
            &:first-child{padding-left:0;}
            &:last-child{margin-right:0;}
        }

        @media only screen and (min-width: 992px){
            font-size:18px;
            margin-right:4%;
        }

    };

    &-step-contents{
        margin-top:15px;
        max-width: 600px;
    }   
}

.warning-label{
    padding: 4px 6px;
    background-color: #fdefba;
    color: #544406;
    a{
        color:inherit;
        font-weight: bold;
    }
}

label{
    font-weight:normal;
}
.form-control{ 
    border-color:#ccc;
    border-radius:2px;
    box-shadow:none;
    padding: 6px 8px;
}

.radio{
    margin-top:0;
}

label.input-custom{
    @size: 22px;

    position: relative;
    line-height:22px;
    padding-left:30px;
    input[type="radio"], input[type="checkbox"]{
        width: @size;
        height: @size;
        opacity:0;
        margin-top:0;
        cursor:pointer;
        &:focus  ~ .input-custom-skin {
            outline: 1px dotted #212121;
            outline: 5px auto -webkit-focus-ring-color;
        }
    }
    .input-custom-skin{
        width: @size;
        height: @size;
        display: block;
        position: absolute;
        left:0;
        top:0;
        pointer-events: none;
        border:1px solid #CCC;
        background:white;
    }

    &:hover{
        .input-custom-skin{
            box-shadow: inset -1px 1px 7px rgba(0,0,0,.1);
            border-color: @grey;
        }
    }

    input[type="radio"] {
        ~ .input-custom-skin {
            border-radius:100px;
        }
        &:checked, &:hover:checked {
            ~ .input-custom-skin{
                border-color: @steel;
                background-color:@steel;
                &:after{
                    content: '';
                    display: block;
                    position: absolute;
                    left: 4px;
                    top: 4px;
                    width: 12px;
                    height: 12px;
                    border-radius: 100px;
                    border: 2px solid white;
                }
            }
        }
    }

    input[type="checkbox"] {
        ~ .input-custom-skin {
            border-radius:4px;
        }
        &:checked, &:hover:checked {
            ~ .input-custom-skin{
                border-color: @steel;
                background-color:@steel;
                &:after{
                    content: "\f00c";
                    font-family: 'FontAwesome';
                    display: block;
                    position: absolute;
                    left: -1px;
                    top: -1px;
                    width: @size;
                    height: @size;
                    line-height: @size;
                    color:white;
                    text-align:center;
                }
            }
        }
    }
}


.btn-primary {
    border-radius: 3px;
    text-align: center;
    background-color: @blue;
    border: 0;
    min-height: 36px;
    padding: 0 20px;
    cursor: pointer;
    transition: all linear .05s;
    font-weight: 500;
    line-height: 36px;
    position: relative;
    &:hover, &:focus{
        background-color:#00427c;
        border-color: #00427c;
    }
}

.btn-secondary{
    background:transparent;
    border-color:transparent;
    color: @blue;
    &:hover, &:focus{
        background: #e5e5e5;
        border-color:#e5e5e5;
        color: #00427c;
    }
}

.fieldset{
    padding-left:15px;
    border-left:2px solid #e5e5e5;
    margin-bottom:35px;
    .legend{
        color:#999;
        text-transform: uppercase;
        margin:0;
        margin-bottom:10px;
        text-indent:-10px;
        font-size:14px;
        font-weight:normal;
    }
}


.col-offset-right(@i, @type) when (@i >= 0) {
    .col-@{type}-offset-right-@{i} {
        margin-right: percentage((@i / @grid-columns));
    }
    .col-offset-right(@i - 1, @type);
};
.col-offset-right(@grid-columns, xs);
@media (min-width: 768px) {
    .col-offset-right(@grid-columns, sm);
}
@media (min-width: 992px) {
    .col-offset-right(@grid-columns, md);
}
@media (min-width: 1200px) {
    .col-offset-right(@grid-columns, lg);
}

.password-validation-message-list{
    padding:5px 10px;
    background: #f5f5f5;
    .password-validation-message{
        margin-bottom: 5px;
        .fa{
            margin-right:5px;
        }
    }
}

.confirmation-message{
    padding: 10px 15px;
    border-left: 5px solid #06303D;
    h2{
        margin-top:0;
        color: @steel;
    }
}

.has-error {
    .control-label{
        color:@red;
    }
    .form-control {
        border-color: @red;
    }
}

.btn-controls{
    margin-top: 20px;
    margin-bottom: 15px;
    .btn-secondary{
        margin-left:1em;
    }
}