// .password-validation-message i {
//     width: 18px;
//     display: inline-block;
//     text-align: center;
// }

// .form-control + .password-validation-message {
//     margin-top: 10px;
// }

.validation-tip {
    color: @grey;
    margin-bottom:10px;
    &:empty{
        display:none;
    }
}