@alert-animation-duration: 0.5s;
@alert-animation-time: cubic-bezier(0,.3,.5,1);
@alert-animation-offset: -30px;

.alerts {
    position: fixed;
    right: 20px;
    top: 72px;
    z-index: 1500;

    .alert {
        width: 300px;
        margin-bottom: 1em;
        box-shadow: 0 3px 7px 1px rgba(0, 0, 0, 0.07);
    }
}

.alert-animation.ng-enter,
.alert-animation.ng-leave {
    transition: @alert-animation-duration @alert-animation-time all;
}

.alert-animation.ng-enter,
.alert-animation.ng-leave.ng-leave-active {
    -webkit-transform: translate3d(0, @alert-animation-offset, 0);
    transform: translate3d(0, @alert-animation-offset, 0);
    opacity:0;
}
.alert-animation.ng-leave,
.alert-animation.ng-enter.ng-enter-active {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity:1;
}
