@steel: #06303D;

html, body {
  height: 100%;
}

#page-content{
  min-height: 100%;
  margin-bottom: -70px; 
  &:after {
    content: "";
    display: block;
  }
}

#page-footer{
  background:@steel;
  color:white;
}

#page-footer, #page-content:after {
  height: 70px; 
}

.header-main-wrapper{
  background: @steel;
}
.header-main{
  padding:15px 20px;
  height:54px;
  @media only screen and (min-width: 992px){
    padding-top:35px;
    height:104px;
  }
}

.header-main, .page-title, .wide-container, .language-selector{
  max-width:1450px;
  margin:0 auto;
}

.wide-container, .page-title{
  padding: 15px;
  @media only screen and (min-width: 992px){
    padding: 25px 20px;
  }
}

.page-title-wrapper{
  background:#f3f3f3;
}

.page-title{
  h1{
    margin:0;
    font-size:20px;
    color: @steel;
    @media only screen and (min-width: 600px){
      font-size:24px;
    }
    @media only screen and (min-width: 992px){
      font-size:32px;
    }
  }
}

.registration-form-wrapper {
  .loading-overlay {
    position: absolute;
    z-index: 2000;
    background-color: rgba(255,255,255,0.9);
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
  }
}

.registration-form {
  display: block;
  position: relative;

  .multi-step-form-step-title {
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
    margin-bottom: 20px;
  }

  fieldset {
    margin: 0 0 20px;
    border: 1px solid #eee;
    border-radius: 4px;
    padding: 10px;
  }

  legend {
    font-size: inherit;
    margin: 0;
    width: auto;
    border: 0;
  }

  .agreement-section {
    margin-top: 20px;
    .form-group {
      text-align: center;
    }
  }

  // address field select
  .ui-select-container .caret {
    display: none;
    + .btn {
      margin-right: -5px !important;
    }
  }
  .ui-select-container .spinner {
    right: -30px !important;
  }
  .ui-select-toggle:hover {
    background-color: white;
    cursor: text;
  }
  fieldset[disabled] .ui-select-toggle {
    cursor: not-allowed;
  }
}

.language-selector-wrapper{
  background:#16211F;
}

.language-selector{
  padding:0 20px;
  height:35px;
  font-size:13px;
  .btn{
    color:white;
    font-weight: 500;
    background:transparent !important;
    border:0 !important;
    line-height: 35px;
    padding: 0;
  }
  @media only screen and (min-width: 750px){
    font-size:14px;
    height:50px;
    .btn{
      line-height: 50px;
    }
    .btn-group{
      float:right;
      .dropdown-menu{
        left: auto;
        right: 0;
      }
    }
  }
}