
.address-field, .add-address {
  .ui-select-container[name="address"] {
    text-transform: uppercase;
  }
  input[type="text"] {
    text-transform: uppercase;
  }
  > .fa-spin {
    z-index:1000;
    margin-top: -7px;
    margin-right: -10px;
  }
}

.manual-address-form {
  margin-bottom: 0;
  
  .clear-btn {
    height: 59px;
    
    button {
      margin-top: 26px;
      height: 34px;
    }
  }

  .help-text {
    > div:first-child {
      > div {
        font-size: 25px;
        font-weight: bold;
      }
    }

    > div:last-child {
      > div:first-child {
        font-size: 16px;
        font-weight: bold;
        color: red;
      }
      
      > div:last-child {
        font-weight: bold;
        font-size: 16px;
      }
    }
  }
}
