
.help-overlay {
    position: fixed;
    right: 0;
    left: 0;
    z-index: 1030;
    transition: 0.2s linear all;
    -webkit-transition: 0.2s linear all;

    &.ng-hide {
      opacity: 0;
    }

    > div {
      > div {
        background-color: white;
        margin-top: 20px;
        margin-left: 640px;
        width: 220px;
        padding: 0 5px;
        box-shadow: 0 0 7px 7px white;

        div:first-child {
          font-size: 27px;
          font-weight: bold;
        }

        div:last-child {
          color: red;
          font-size: 16px;
          font-weight: bold;
          
          a {
            color: red;

            &:focus, &:hover {
              text-decoration: none;
            }
          }
        }
      }
    }
}
