
.ui-select-container {
  .ui-select-toggle {
    height: 34px;
    text-align: left;
  }

  .ui-select-allow-clear + .caret {
    display: none;
  }

  .ui-select-match {
    overflow : hidden;

    .caret {
      position: absolute;
      right: 12px;
      top: 14px;
    }

    .ui-select-clear {
      border: 0;
      background-color: transparent;
      position: absolute;
      right: 1px;
      top: 1px;
      font-weight: normal;
      font-size: 11px;
      border-radius: 0 4px 4px 0;
      height: 32px;
      width: 30px;
    }

    &[disabled] {
      cursor: not-allowed;
      background-color: #eee;
      opacity: 1;
    }

    .btn-link {
      background-color: transparent;
      position: absolute;
      right: 0;
      font-weight: normal;
      font-size: 11px;
      margin-right: 5px !important;
    }
  }

  .ui-select-multiple {
    .ui-select-search {
      height: 26px !important;
    }
  }

  .ui-select-choices-row .row {
    margin-bottom: 0;
  }
}

